<template>
  <div>
    <Form
      ref="customerData"
      id="customerData"
      :model="customerData"
      :rules="ruleValidate"
      label-position="top"
    >
        <FormItem label="报备客户" prop="advertiserId">
          <Select
            size="small"
            v-model="customerData.advertiserId"
            filterable
            clearable
            @on-change="customerChange"
            placeholder="请选择一个客户"
          >
            <Option
              v-for="(customer, index) in customers"
              :value="customer.value"
              :key="index"
              >{{ customer.label }}</Option
            >
          </Select>
        </FormItem>

      <FormItem label="报备品牌" prop="brandId">
        <Select
          size="small"
          v-model="customerData.brandId"
          filterable
            @on-change="initadxcompany"
          placeholder="请选择一个品牌"
        >
          <Option v-for="brand in brands" :value="brand.id" :key="brand.id">{{
            brand.name
          }}</Option>
        </Select>
      </FormItem>
 <FormItem label="报备业主"  prop="publisherId">
        <Select
          size="small"
          v-model="customerData.publisherId"
          filterable
          @on-change="initadxcompany"
          placeholder="请选择一个业主"
        >
          <Option
            v-for="(company, index) in publishers"
            :value="company.id"
            :key="index"
            >{{ company.name }}</Option
          >
        </Select>
      </FormItem>
      <FormItem label="报备运营商"  prop="companyId">
        <Select
          size="small"
          v-model="customerData.companyId"
          filterable
          multiple
          placeholder="请选择运营商"
        >
          <Option
            v-for="(company, index) in companys"
            :value="company.id"
            :key="index"
            >{{ company.name }}</Option
          >
        </Select>
      </FormItem>
      <FormItem label="备注" prop="description">
        <Input
          size="small"
          v-model="customerData.description"
          placeholder="请填写备注信息"
        />
      </FormItem>
      <FormItem class="text-right">
        <Button
          class="btn-black m-l-8"
          size="small"
          @click="handleReset('customerData')"
          >重置</Button
        >
        <Button
          class="m-l-8"
          type="success"
          size="small"
          :disabled="!(customerData.advertiserId&&customerData.brandId&&customerData.companyId.length)"
          @click="handleSubmit('customerData')"
          >提交</Button
        >
      </FormItem>
    </Form>
  </div>
</template>

<script>
import { getAvailableAdvertiserPage } from '@/api/crm/advertiser'
import { getBrandList } from '@/api/crm/brand'
import { getadxcompany, reportbrand } from '@/api/crm/companyadvertiser'
import { getadxpublisher } from '@/api/os/publishercompany'

export default {
  data () {
    return {
      publishers: [],
      publisherId: this.$store.getters.token.userInfo.publisherId,
      userId: this.$store.getters.token.userInfo.userId,
      customers: [],
      brands: [],
      companys: [],
      searchCustomerQuery: {
        pageNumber: 1,
        pageSize: 100,
        keyword: '',
        userId: '',
        advertiserId: '',
        publisherId: ''
      },

      customerData: {
        advertiserId: null,
        publisherId: null,
        brandId: null,
        companyId: [],
        description: ''
      },
      ruleValidate: {
        advertiserId: [{ required: true, message: ' ', trigger: 'blur' }],
        brandId: [{ required: true, message: ' ', trigger: 'blur' }],
        publisherId: [{ required: true, message: ' ', trigger: 'blur' }],
        companyId: [{ required: true, message: ' ', trigger: 'blur' }]
      }
    }
  },
  created () {
    this.initCurrentCustomers()
    this.initadxpublisher()
  },
  methods: {
    initCurrentCustomers () {
      this.customers = []
      this.searchCustomerQuery.publisherId = ''

      this.searchCustomerQuery.userId = this.userId
      getAvailableAdvertiserPage(this.searchCustomerQuery).then((res) => {
        this.customers = res.list.map((item) => {
          return {
            value: item.id,
            label: item.name
          }
        })
      })
    },
    // 获取支持adx的租户列表
    initadxpublisher () {
      getadxpublisher({}).then(res => {
        this.publishers = res
      })
    },

    customerChange () {
      this.brands = []
      if (this.customerData.advertiserId) {
        const brandquery = {
          advertiserId: this.customerData.advertiserId,
          userId: this.userId,
          status: '2,3,5',
          group: true
        }
        getBrandList(brandquery).then((res) => {
          this.brands = res
        })
      }
    },
    initadxcompany () {
      this.companys = []
      if (this.customerData.publisherId && this.customerData.brandId) {
        const query = {
          brandId: this.customerData.brandId,
          publisherId: this.customerData.publisherId
        }
        getadxcompany(query).then((res) => {
          this.companys = res
        })
      }
    },
    conflictChangePage (currentPage) {
      this.customerQuery.pageNumber = currentPage
      this.getConflictPageData()
    },
    handleSubmit () {
      const query = {
        brandId: this.customerData.brandId,
        publisherId: this.customerData.publisherId,
        mark: this.customerData.description,
        companyIds: JSON.stringify(this.customerData.companyId)
      }
      reportbrand(query).then(res => {
        if (res && !res.errcode) {
          this.$Notice.success({ desc: '报备品牌成功！' })
          this.$store.commit('set_advertiser_update', new Date())
        }
      })
    },
    handleReset (name) {
      this.$refs[name].resetFields()
    }
  },
  watch: {}
}
</script>
